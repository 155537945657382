(function(w,d,$){
    var main_history_id = 0;
    var main_dist_table = 0;
    $('#datepicker').datepicker({
        format: "mm-dd-yyyy",
        autoclose: true
    });  
    $('#datepicker-claims').datepicker({
        format: "yyyy-mm-dd",
        autoclose: true
    });
    $('#datepicker-earnings').datepicker({
        format: "mm-dd-yyyy",
        autoclose: true
    });
    $('#datepicker-topdrugs').datepicker({
        format: "mm-dd-yyyy",
        autoclose: true
    }); 
    $('#datepicker-pharmacies').datepicker({
        format: "mm-dd-yyyy",
        autoclose: true
    }); 
    $('.datepicker').datepicker({
        format: "mm-dd-yyyy",
        autoclose: true
    });
    $('.pdf-datepicker').datepicker({
        format: "mm-dd-yyyy"       
    }).on('changeDate', function(e) {
        var self = $(e.currentTarget);
        var d = new Date(self.val());
             
        var label = $(self.data('label'));        
        label.text(d.toDateString().substr(4,12));
    });
    
    /*RX-Cut and Rx-Data*/
    $( "#dataRx" ).change(function() {
        var url = $(this).data('datarxurl');
        var val = $('#dataRx').val();
        //window.location=url+'?dataRx='+val;
    });

    ///////////////////////
    //Email Templates Start///
    if(typeof w.tinymce === 'undefined') {
        // $('head').append('<script src="https://cdn.jsdelivr.net/tinymce/4.1.2/tinymce.min.js"></script>');
        console.log('Unable to load Tinymce ...');
    }
    setTimeout(function(){
        tinymce.init({ menubar:false,selector:'.email-templates-textarea', height:350 });
    }, 1000);

    $( ".span-shortcode" ).on("click", function () {
        var thisShortcode = $(this).text();

        tinymce.activeEditor.execCommand('mceInsertContent', false, thisShortcode);
    });
    //Email Templates End///
    Number.prototype.formatNumber = function(c, d, t){
        var n = this, 
            c = isNaN(c = Math.abs(c)) ? 2 : c, 
            d = d == undefined ? "." : d, 
            t = t == undefined ? "," : t, 
            s = n < 0 ? "-" : "", 
            i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", 
            j = (j = i.length) > 3 ? j % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };
    //Check the status of imported data
    if($('#loading-import-data').length){
        var route = $('#loading-import-data').data('route');
        var check_import_status = function(route){
            if(!route) return false;
                        
            $.post(route, function(data){
                
                if(data.done == true && data.process == 1){
                    $('#loading-import-data').html('<h3>The data was successfully imported.</h3>');
                    location.reload();
                }else if(data.done == false && data.process == 1){
                    $('#loading-import-data').html('<h3 style="color:red">Something went wrong with the last import!</h3>');
                    //location.reload(); 
                }else{
                    check_import_status(route);
                }
            });           
        }
        check_import_status(route);
    }
    //Check the status of imported data End
    
    //Transaction Details Start
    $(".view-transactiondetails").on('click', function (event) {
        event.preventDefault();
        var url = $(this).data('get-url');
        $('#detailsiframe').attr('src', url)
    });
    //Transaction Details End

    //Overall Start
    $(".modaloverall").on('click', function (event) {
        event.preventDefault();
        var url = $(this).data('url'); 
        $('#overalliframe').attr('src', url)
    });
    //Overall End

    //List Management Start
    $(".listDistributors").on('click', function (event) {
        event.preventDefault();
        var url = $(this).data('get-url');
        $('#listDistributors').attr('src', url)
    });
    //List Management End
    
     //Send Email Start
    $(".sendpdf-template-email").on('click', function (event) {
        event.preventDefault();
        $('#sendemailiframe').contents().find("body").html('');
        var url = $(this).data('get-url');
        $('#sendemailiframe').attr('src', url);
    });
    //Send Email End

    $("#selectAll").change(function() {
        $("input:checkbox").prop('checked', $(this).prop("checked"));
    });

    //Store the selected distributors into session
    var sendemailiframe = $('#sendemailiframe');
    sendemailiframe.on("load", function () { 
        sendemailiframe.contents().find('.sel-distirbutors').click(function(){
            var url = sendemailiframe.contents().find('#next-form').attr('action');
            var distributor_id = $(this).val();
            
            if(distributor_id === 'all'){
                var action = $(this).is(':checked') ? 'addall':'removeall';
            }else{
                var action = $(this).is(':checked') ? 'add':'remove';
            }            
            
            var next_step_btn = sendemailiframe.contents().find('.btn-next-step');           
            next_step_btn.attr('disabled', 'disabled');           
            
            $.ajax({
                url: url,
                method: 'GET',
                data: { 'distributor_id':distributor_id, 'action':action }
            }).done(function(){
                next_step_btn.removeAttr('disabled');               
            });
        });
        
        sendemailiframe.contents().find('.include-pdf-check').click(function(){
            var self = $(this);
            var sp_labels = sendemailiframe.contents().find('.send-summary-inc-pdf');
                        
            if(self.is(':checked')){                
                sp_labels.show();
            }else{                
                sp_labels.hide();
            }
        });    
        
        sendemailiframe.contents().find('.input-sm.date_from').bind('dateselected', function(){
            var date_from =  sendemailiframe.contents().find('.label-date-from'); 
            date_from.text($(this).val());
        });
        
    });
    //Store the selected distributors into session Ens
       
    //Romove the selected user form send PDF interface, step 2
    $('.remove-selected-user').click(function(){
        var parent = $(this).closest('.row');
        var distributor_id = $(this).data('id');
        var url = sendemailiframe.contents().find('#next-form').attr('action');
        var action = 'remove';       
        
        $.ajax({
            url: url,
            method: 'GET',
            data: { 'distributor_id':distributor_id, 'action':action }
        }).done(function(res){
            $('#sel-users-count').text(res.count);
            parent.remove();
        });
    });
    //Romove the selected user form send PDF interface, step 2 END
        
    //Send Email Template Start
    $(".send-template-email").on('click', function (event) {
        event.preventDefault();        
        $('#send-template-name').html($(this).data('template-name'));
        $('#send-template-id').val($(this).data('template-id'));
    });
    //Send Email Template End
        
    //Pull the distributor chart data for admin
    Highcharts.setOptions({
        lang: {
            thousandsSep: ','
        }
    });
    if($('#view-distributor-data').length){
        $.post($('#distrib_ajax_route').val(), function(data){
            $('#chart-container').highcharts({
                chart: {
                    plotBackgroundColor: null, 
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: 'Claims by Pharmacy Name'
                },
                tooltip: {
                    pointFormat: '{point.y:.0f} Claims'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true, 
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                },
                series: [{
                    name: 'Brands',
                    colorByPoint: true,
                    data: data
                }]
            });        
        });
    }    
    //Transaction Details End///
    /*Widget Checkboxes*/
   
    $('.claims-checkbox').change(function(event) {
        var getUrl = $(this).data('get-claims');
        claims(event,getUrl);
    });

    $('.earnings-checkbox').change(function(event) {
        var getUrl = $(this).data('get-earnings');
        earnings(event,getUrl);
    });

    $('.medications-checkbox').change(function(event) {

        var medicationsCheck = $('input[name=medications-check]').is(':checked');
        var medicationsOverridesCheck = $('input[name=medications-overrides-check]').is(':checked');
        $('input.medications-checkbox').not(this).prop('checked', false);  

        var url = $(this).val();
        var getUrl = $(this).data('get-overallpercent');
        overallPercent(event,getUrl);


        if($('input[name=medications-check]').is(':checked')==false && $('input[name=medications-overrides-check]').is(':checked')==false){
            $('input[name=medications-check]').prop('checked',true);

            var url = $('input[name=medications-check').val();
            var getUrl = $(this).data('get-overallpercent');
            overallPercent(event,getUrl);
            document.getElementById("topdrugsiframe").contentWindow.location = url;
            return true;
        }

        document.getElementById("topdrugsiframe").contentWindow.location = url;
    });

    $('.pharmacies-checkbox').change(function(event) {
        $('input.pharmacies-checkbox').not(this).prop('checked', false);  
        var getUrl = $(this).data('get-pharmacies');
        getPharmacies(event,getUrl);

        if($('input[name=pharmacies-check]').is(':checked')==false && $('input[name=pharmacies-overrides-check]').is(':checked')==false){
            $('input[name=pharmacies-check]').prop('checked',true);
        }
    });

    function claims(event,getUrl)
    {
        event.preventDefault();
        //var getUrl = $(this).data('get-claims');
        var start = $("input[name=widget-start]").val();
        var end = $("input[name=widget-end]").val();
        var selectRx = $("input[name=selectRx]").val();
        var claimsCheck = $('input[name=claims-check]').is(':checked');
        var claimsOverridesCheck = $('input[name=claims-overrides-check]').is(':checked');
        var ajax = true;

        if(ajax == true){
            ajax = false;
            $('#loadingunu').show();
            var request = $.ajax({
                    url: getUrl,
                    method: "GET",
                    data: { 
                        start: start,
                        end: end,
                        claimsCheck: claimsCheck,
                        claimsOverridesCheck: claimsOverridesCheck,
                        selectRx: selectRx,
                        _token: $("#_token").val()
                    },
                    dataType: "json"
                });
            request.done(function (data) {
                ajax = true;
                var months = data.months;
                var selectRx = data.selectRx;

                var chartData = new Array;
                for(var index in data.monthsClaims){ 
                    var arr = [index, data.monthsClaims[index]]; 
                    chartData.push(arr);
                }

                var chartOverrides = new Array;
                for(var index in data.monthsOverrides){ 
                    var arr = [index, data.monthsOverrides[index]]; 
                    chartOverrides.push(arr);  
                }

                if(claimsCheck == true && claimsOverridesCheck == false){
                    if(selectRx==1){
                        $('#total-totalClaims').html((data.nrClaims+data.nrReversals).formatNumber(0, '', ','));
                        $('#total-myclaims').html((data.nrClaims).formatNumber(0, '', ','));
                        $('#total-overrides').html('*');
                        $('#total-reversal').html((data.nrReversals).formatNumber(0, '', ','));
                    }

                    if(selectRx==2){
                        $('#total-totalClaims').html((data.nrClaims+data.nrReversed).formatNumber(0, '', ','));
                        $('#total-myclaims').html((data.nrNetClaims).formatNumber(0, '', ','));
                        $('#total-overrides').html('*');
                        $('#total-reversal').html((data.nrReversals).formatNumber(0, '', ','));
                    }

                    var dataArray = [{
                        name: 'My Net Claims',
                        data: chartData,
                    }]

                }
                else if(claimsCheck == false && claimsOverridesCheck == true){
                    $('#total-totalClaims').html('*');
                    $('#total-myclaims').html('*');
                    $('#total-overrides').html((data.nrOverrides).formatNumber(0, '', ','));
                    $('#total-reversal').html('*');
                    var dataArray = [{
                        name: 'Overrides',
                        data: chartOverrides
                    }]
                }else{
                    if(selectRx==1){
                        $('#total-totalClaims').html((data.nrClaims+data.nrReversals).formatNumber(0, '', ','));
                        $('#total-myclaims').html((data.nrClaims).formatNumber(0, '', ','));
                    }
                    if(selectRx==2){
                        $('#total-totalClaims').html((data.nrClaims+data.nrReversed).formatNumber(0, '', ','));
                        $('#total-myclaims').html((data.nrNetClaims).formatNumber(0, '', ','));
                    }
                    $('#total-overrides').html((data.nrOverrides).formatNumber(0, '', ','));
                    $('#total-reversal').html((data.nrReversals).formatNumber(0, '', ','));
                    var dataArray = [{
                        name: 'My Net Claims',
                        data: chartData,
                    },{
                        name: 'Overrides',
                        data: chartOverrides
                    }]
                }
                console.log(months,chartData,chartOverrides);
                var chart = new Highcharts.Chart({
                    chart: {
                        type: 'column',
                        renderTo: 'chart'
                    },
                    title: {
                        text: 'Paid Prescription Claims by Date'
                    },
                    subtitle: {
                        text: ''
                    },
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: true,
                                format: '{point.y:.0f}'
                            }
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Number of Paid Claims',
                            style: {
                                color: 'black',
                                fontSize:'15px'
                            }
                        }
                    },
                    xAxis: {
                        categories: months,
                        crosshair: true
                    },
                    series: dataArray
                });
                $('#loadingunu').hide();
            });
        }
    }

    function earnings(event,getUrl)
    {
        event.preventDefault();
        //var getUrl = $(this).data('get-earnings');
        var start = $("input[name=widget-start]").val();
        var end = $("input[name=widget-end]").val();
        var selectRx = $("input[name=selectRx]").val();
        var earningsCheck = $('input[name=earnings-commissions]').is(':checked');
        var earningsOverridesCheck = $('input[name=earnings-overrides-check]').is(':checked');
        $('#loadingdoi').show();
        $('.widget-submit').prop('disabled', true);
        var request = $.ajax({
                url: getUrl,
                method: "GET",
                data: { 
                    start: start,
                    end: end,
                    selectRx: selectRx,
                    earningsCheck: earningsCheck,
                    earningsOverridesCheck: earningsOverridesCheck,
                    _token: $("#_token").val()
                },
                dataType: "json"
            });
        request.done(function (data) {   
            
            var chartData = new Array;
            for(var index in data.commissionMonthsValues){ 
                var arr = [index, data.commissionMonthsValues[index]]; 
                chartData.push(arr);  
            } 
            var chartOverrides = new Array;
            for(var index in data.overrideCommissionMonthsValues){ 
                var arr = [index, data.overrideCommissionMonthsValues[index]]; 
                chartOverrides.push(arr);  
            }

            if(earningsCheck == true && earningsOverridesCheck == false){
                $('#total-earnings').html('$ '+(data.commission).formatNumber(2, '.', ','));
                $('#total-commissions').html('$ '+(data.commission).formatNumber(2, '.', ','));
                $('#total-earnings-overrides').html('*');
                
                var dataArray = [{
                    name: 'My Commissions',
                    data: chartData,
                }]
            }
            else if(earningsCheck == false && earningsOverridesCheck == true){
                $('#total-earnings').html('$ '+(data.overrideCommission).formatNumber(2, '.', ','));
                $('#total-commissions').html('*');
                $('#total-earnings-overrides').html('$ '+(data.overrideCommission).formatNumber(2, '.', ','));
                var dataArray = [{
                    name: 'Overrides Commissions',
                    data: chartOverrides
                }]
            }else{
                $('#total-earnings').html('$ '+(data.totalCommissions).formatNumber(2, '.', ','));
                $('#total-commissions').html('$ '+(data.commission).formatNumber(2, '.', ','));
                $('#total-earnings-overrides').html('$ '+(data.overrideCommission).formatNumber(2, '.', ','));
                var dataArray = [{
                    name: 'My Commissions',
                    data: chartData,
                },{
                    name: 'Overrides Commissions',
                    data: chartOverrides
                }]
            }

            var chart = new Highcharts.Chart({
                chart: {
                    type: 'column',
                    renderTo: 'earnings-chart'
                },
                 title: {
                    text: 'Monthly Earnings to Date'
                },
                subtitle: {
                    text: ''
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '$ {point.y:.2f}'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Monthly Commissions - $',
                        style: {
                            color: 'black',
                            fontSize:'15px'
                        }
                    }
                },
                xAxis: {
                    categories: data.months,
                    crosshair: true
                },
                series: dataArray
            });
            $('#loadingdoi').hide();
            $('.widget-submit').prop('disabled', false);
        });
    }

    function overallPercent(event,getUrl)
    {   
        var start = $("input[name=widget-start]").val();
        var end = $("input[name=widget-end]").val();

        var request = $.ajax({
            url: getUrl,
            method: "GET",
            data: { 
                start: start,
                end: end,
                _token: $("#_token").val()
            },
            dataType: "json"
        });
        request.done(function (data) { 
            $('#overallPercent').empty();
            $('#overallPercent').append(parseFloat(data).toPrecision(4));
        });    
    }

    function getPharmacies(event,getUrl)
    {   
        event.preventDefault();
        //var getUrl = $(this).data('get-pharmacies');
        var start = $("input[name=widget-start]").val();
        var end = $("input[name=widget-end]").val();
        var selectRx = $("input[name=selectRx]").val();
        $('#loadingpatru').show();

        var request = $.ajax({
            url: getUrl,
            method: "GET",
            data: { 
                start: start,
                end: end,
                selectRx:selectRx,
                _token: $("#_token").val()
            },
            dataType: "json"
        });
        request.done(function (data) {  
            $('#top-pharmacy').empty();
            $('#top-pharmacy').html(data.html);

            $('#pharmacies-chart').highcharts({
                chart: {
                    type: 'pie'
                },
                title: {
                    text: 'Pharmacies'
                },
                subtitle: {
                    text: ''
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}: {point.y:.1f}%'
                        }
                    }
                },

                tooltip: {
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                },
                series: [{
                    name: 'Pharmacy',
                    colorByPoint: true,
                    data: data.data
                }],
            });
        });
        $('#loadingpatru').hide();
    }
    /*-------------------*/
    //Distributor Dashboard Start///
    $(".widget-submit").on('click', function (event) {
        var getUrl = $(this).data('get-claims');
        claims(event,getUrl);
    });

    $(".widget-submit").on('click', function (event) {
        var getUrl = $(this).data('get-earnings');
        earnings(event,getUrl);
    });


    $(".widget-submit").on('click', function (event) {
        $('#loadingtrei').show();
        var getUrl = $(this).data('topdrugs-iframe');
        var start = $("input[name=widget-start]").val();
        var end = $("input[name=widget-end]").val();
        var distributor = $(this).data('distributor');
        var selectRx = $("input[name=selectRx]").val()

        if(distributor==undefined){
            var url = getUrl+'&start='+start+'&end='+end+'&distributor='+distributor+'&selectRx='+selectRx;
        }else{
            var url = getUrl+'?start='+start+'&end='+end+'&distributor='+distributor+'&selectRx='+selectRx;
        }


        $('#topdrugsiframe').attr('src', url);

        $('#topdrugsiframe').load(function() {
            $('#loadingtrei').hide();
        });
    });

    $('#topdrugsiframe').load(function() {
        $('#loadingtrei').hide();
    });

    $(".widget-submit").on('click', function (event) {
        var getUrl = $(this).data('get-pharmacies');
        getPharmacies(event,getUrl);
    });

    $(".widget-submit").on('click', function (event) {
        var getUrl = $(this).data('get-overallpercent');
        overallPercent(event,getUrl);
    });

    if($('#homepage').length){
        var getUrl = $('#overallPercentUrl').val();
        var request = $.ajax({
            url: getUrl,
            method: "GET",
            data: { 
                _token: $("#_token").val()
            },
            dataType: "json"
        });
        request.done(function (data) { 
            $('#overallPercent').empty();
            $('#overallPercent').append(parseFloat(data).toPrecision(4));
        });    
    }

    $(".modaliframe").on('click', function (event) {
        var url = $('#messagesiframeurl').val();
        $('#messages').attr('src', url)
    });
    //Start Auto Load when on Page
    if($('.homepagemessages').length){
        setInterval(function(){
            var getUrl = $('#messagesajax').val();
            var request = $.ajax({
                type: "GET",
                url: getUrl,
                  data: { 
                    _token: $("#_token").val()
                    },
                    dataType: "json"
            });
            request.done(function (data) {  
                if(data==0 || data==null){
                    $('.message').hide();
                }else{
                    $('.message').show();
                }
                $('.dashboard-message-nr').html(data+' new');
            });   
        }, 5000);
    }

 if($('#homepage').length){
        $('#loadingunu').show();
        $.post($('#claims_ajax_route').val(), function(data){
            $('#total-claims').html(data.nrClaims+data.nrOverrides);
            $('#total-myclaims').html(data.nrClaims);
            $('#total-overrides').html(data.nrOverrides);
            var claimsCheck = $('input[name=claims-check]').is(':checked');
            var claimsOverridesCheck = $('input[name=claims-overrides-check]').is(':checked');
            var selectRx = data.selectRx;
            var months = Object.keys(data.monthsClaims);

            var monthsOverrides = data.monthsOverrides;
            var chartData = new Array;
            for(var index in data.monthsClaims){ 
                var arr = [index, data.monthsClaims[index]]; 
                chartData.push(arr);  
            } 
            var chartOverrides = new Array;
            for(var index in data.monthsOverrides){ 
                var arr = [index, data.monthsOverrides[index]]; 
                chartOverrides.push(arr);  
            }
            if(claimsCheck == true && claimsOverridesCheck == false){
                $('#total-totalClaims').html((data.nrClaims+data.nrReversals).formatNumber(0, '', ','));
                $('#total-myclaims').html((data.nrClaims).formatNumber(0, '', ','));
                $('#total-overrides').html('*');
                $('#total-reversal').html((data.nrReversals).formatNumber(0, '', ','));
        
                var dataArray = [{
                    name: 'My Net Claims',
                    data: chartData,
                }]
            }
            else if(claimsCheck == false && claimsOverridesCheck == true){
                $('#total-totalClaims').html((data.nrClaims+data.nrReversals).formatNumber(0, '', ','));
                $('#total-myclaims').html('*');
                $('#total-overrides').html((data.nrOverrides).formatNumber(0, '', ','));
                $('#total-reversal').html('*');
                var dataArray = [{
                    name: 'Overrides',
                    data: chartOverrides
                }]
            }else{
                $('#total-totalClaims').html((data.nrClaims+data.nrReversals).formatNumber(0, '', ','));
                $('#total-myclaims').html((data.nrClaims).formatNumber(0, '', ','));
                $('#total-overrides').html((data.nrOverrides).formatNumber(0, '', ','));
                $('#total-reversal').html((data.nrReversals).formatNumber(0, '', ','));
                var dataArray = [{
                    name: 'My Net Claims',
                    data: chartData,
                },{
                    name: 'Overrides',
                    data: chartOverrides
                }]
            }
            
            var chart = new Highcharts.Chart({
                chart: {
                    type: 'column',
                    renderTo: 'chart'
                },
                title: {
                    text: 'Paid Prescription Claims by Date'
                },
                subtitle: {
                    text: ''
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y:.0f}'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Number of Paid Claims',
                        style: {
                            color: 'black',
                            fontSize:'15px'
                        }
                    }
                },
                xAxis: {
                    categories: months,
                    crosshair: true
                },
                series: dataArray
            });
            $('#loadingunu').hide();
        });

       $.post($('#pharmacies_ajax_route').val(), function(data){
            $('#loadingpatru').show();
            $('#pharmacies-chart').highcharts({
                chart: {
                    type: 'pie'
                },
                title: {
                    text: 'Pharmacies'
                },
                subtitle: {
                    text: ''
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}: {point.y:.1f}%'
                        }
                    }
                },

                tooltip: {
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                },
                series: [{
                    name: 'Pharmacy',
                    colorByPoint: true,
                    data: data
                }],
            });
            $('#loadingpatru').hide();
        });
    $.post($('#earnings_ajax_route').val(), function(data){
            $('#loadingdoi').show();
            $('#total-earnings').html('$ '+(data.totalCommissions).formatNumber(2, '.', ','));
            $('#total-commissions').html('$ '+(data.commission).formatNumber(2, '.', ','));
            $('#total-earnings-overrides').html('$ '+(data.overrideCommission).formatNumber(2, '.', ','));
            var earningsCheck = $('input[name=earnings-commissions]').is(':checked');
            var earningsOverridesCheck = $('input[name=earnings-overrides-check]').is(':checked');

            var chartData = new Array;
            for(var index in data.commissionMonthsValues){ 
                var arr = [index, data.commissionMonthsValues[index]]; 
                chartData.push(arr);  
            } 
            var chartOverrides = new Array;
            for(var index in data.overrideCommissionMonthsValues){ 
                var arr = [index, data.overrideCommissionMonthsValues[index]]; 
                chartOverrides.push(arr);  
            }
            
            if(earningsCheck == true && earningsOverridesCheck == false){
                $('#total-earnings').html('$ '+(data.commission).formatNumber(2, '.', ','));
                $('#total-commissions').html('$ '+(data.commission).formatNumber(2, '.', ','));
                $('#total-earnings-overrides').html('*');
                
                var dataArray = [{
                    name: 'My Commissions',
                    data: chartData,
                }]
            }
            else if(earningsCheck == false && earningsOverridesCheck == true){
                $('#total-earnings').html('$ '+(data.overrideCommission).formatNumber(2, '.', ','));
                $('#total-commissions').html('*');
                $('#total-earnings-overrides').html('$ '+(data.overrideCommission).formatNumber(2, '.', ','));
                var dataArray = [{
                    name: 'Overrides Commissions',
                    data: chartOverrides
                }]
            }else{
                $('#total-earnings').html('$ '+(data.totalCommissions).formatNumber(2, '.', ','));
                $('#total-commissions').html('$ '+(data.commission).formatNumber(2, '.', ','));
                $('#total-earnings-overrides').html('$ '+(data.overrideCommission).formatNumber(2, '.', ','));
                var dataArray = [{
                    name: 'My Commissions',
                    data: chartData,
                },{
                    name: 'Overrides Commissions',
                    data: chartOverrides
                }]
            }
            
            var chart = new Highcharts.Chart({
                chart: {
                    type: 'column',
                    renderTo: 'earnings-chart'
                },
                 title: {
                    text: 'Monthly Earnings to Date'
                },
                subtitle: {
                    text: ''
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '$ {point.y:.2f}'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Monthly Commissions - $',
                        style: {
                            color: 'black',
                            fontSize:'15px'
                        }
                    }
                },
                xAxis: {
                    categories: data.months,
                    crosshair: true
                },
                series: dataArray
            });
            $('#loadingdoi').hide();
            
        });
        
 
    }

/*Distributors payrates/overrides increase*/
    $('.increase_action').click(function(){
        var action = $(this).data('action');
        var group_id = $(this).data('groupid');
        var url = $('#increase_url').val();

        $.ajax({
            url: url,
            method:'GET',
            data:{
                group_id :group_id,
                action :action,
                _token: $("#_token").val()
            }
        }).done(function(data){
            if(data.action=="payrate"){
                $('#payrate').html(data.html);
            }else if(data.action=="override"){
                $('#override').html(data.html);
            }
        });
    });

    if($('#users').length && $('#users').data('usergroupid')!=""){
        var action = 'payrate';
        var dist_id = $('#user_id').val();
        var url = $('#increase_url').val();

        $.ajax({
            url: url,
            method:'GET',
            data:{
                dist_id :dist_id,
                action :action,
                _token: $("#_token").val()
            }
        }).done(function(data){
            if(data.action=="payrate"){
                $('#payrate').html(data.html);
            }else if(data.action=="override"){
                $('#override').html(data.html);
            }
        });
    }

    /*Payrate Save button*/
   /* $("#increase-wrap").on('click','#save_override', function (event) {
        var payrate_increase = $("#increase-wrap").find('#increase_override').val();
        var payrate_month = $("#increase-wrap").find('#increase_month_overrides').val();
        var payrate_year = $("#increase-wrap").find('#increase_year_override').val();
        var flag = true;
        if(payrate_increase==""){
            flag = false;
            $("#increase-wrap").find('#increase_override').addClass('red-input-alert');
        }else{
            $("#increase-wrap").find('#increase_override').removeClass('red-input-alert');
        }

        if(payrate_month==0){
            flag = false;
            $("#increase-wrap").find('#increase_month_overrides').addClass('red-input-alert');
        }else{
            $("#increase-wrap").find('#increase_month_overrides').removeClass('red-input-alert');
        }

        if(payrate_year==""){
            flag = false;
            $("#increase-wrap").find('#increase_year_override').addClass('red-input-alert');
        }else{
            $("#increase-wrap").find('#increase_year_override').removeClass('red-input-alert');
        }

        if(flag == false){ return false;}
    });*/

    $('.save_select_override,.override_edit_selected_1,.override_edit_selected_2').click(function(){
        var user_id = $('#user_id').val();
        var dist_table = $(this).data('dist_table');
        var dist_id = $('#users').data('distid');
        var reset = $('#users').data('resetselectedsession');
        var history_id = $(this).data('historyid');
        main_history_id = $(this).data('historyid');
        main_dist_table = dist_table;
        $('#loading').show();
        $.ajax({
            url: reset,
            method:'GET',
            data:{
                user_id :user_id,
                dist_id :dist_id,
                dist_table :dist_table,
                history_id :history_id,
                _token: $("#csrf-token").val()
            }
        }).done(function(data){
           $('#loading').hide();
        });

        event.preventDefault();
        var url = $(this).data('get-url');
        $('#selectOverridersIframe').attr('src', url);
    });

    $("#increase-wrap").on('click','.override_edit_selected', function () {
        event.preventDefault();
        var url = $(this).data('get-url');
        $('#selectOverridersIframe').attr('src', url);
    });

    $('.save_pay_rate').click(function(){

        var dist_table = $(this).data('dist_table');
        var url = $(this).data('geturl');
        var edit_payrate = $(this).data('editpayrate');
        var user_id = $('#user_id').val();

        var payrate = $('#increase_payrate_'+dist_table).val();
        var month = $('#increase_month_payrates_'+dist_table).val();
        var year = $('#increase_year_payrate_'+dist_table).val();  


        var flag = true;
        if(payrate==""){
            flag = false;
            $("#increase-wrap_"+dist_table).find('#increase_payrate_'+dist_table).addClass('red-input-alert');
        }else{
            $("#increase-wrap_"+dist_table).find('#increase_payrate_'+dist_table).removeClass('red-input-alert');
        }

        if(month==0){
            flag = false;
            $("#increase-wrap_"+dist_table).find('#increase_month_payrates_'+dist_table).addClass('red-input-alert');
        }else{
            $("#increase-wrap_"+dist_table).find('#increase_month_payrates_'+dist_table).removeClass('red-input-alert');
        }

        if(year=="" || year < 2000 || year > 2500){
            flag = false;
            $("#increase-wrap_"+dist_table).find('#increase_year_payrate_'+dist_table).addClass('red-input-alert');
        }else{
            $("#increase-wrap_"+dist_table).find('#increase_year_payrate_'+dist_table).removeClass('red-input-alert');
        }

        if(flag == false){ return false;}

        $('#increase_payrate_'+dist_table).val("");
        $('#increase_month_payrates_'+dist_table).val('0');
        $('#increase_year_payrate_'+dist_table).val("");

        if(payrate=="" || month==0 || year==""){
            return 0;
        }

        var flag = true;
        $.ajax({
            url: url,
            method:'POST',
            data:{
                user_id: user_id,
                dist_table :dist_table,
                payrate :payrate,
                month :month,
                year :year,
                edit_payrate :edit_payrate,
                _token: $("#csrf-token").val()
            }
        }).done(function(data){
            $('#cancel_payrate_'+dist_table).hide();
            $('#save_pay_rate_'+dist_table).data( "editpayrate", 0 );
            if(data.success==true){
                $('#payrate-table_'+dist_table).html(data.html);
                console.log('data.html',data.html);
                $('.message_pay_'+dist_table).html('<bold>Data successfully stored!</bold>');
                $('.message_pay_'+dist_table).addClass('alert alert-success');
            }

            if(data.success==false){
                $('.message').html('<bold>Increase or Year was not set propperly!</bold>');
                $('.message').addClass('alert alert-danger');
                console.log("Increase or Year was not set propperly!");
            }
        });
    });
    






    $('.save_override_data').click(function(){
        var user_id = $('#user_id').val();
        var dist_table = $(this).data('dist_table');
        var url = $(this).data('geturl');
    
        var edit_override = $(this).data('editoverride');

        var payrate = $('#increase_override_'+dist_table).val();
        var month = $('#increase_month_overrides_'+dist_table).val();
        var year = $('#increase_year_override_'+dist_table).val();

        // console.log(payrate,month,year);

        var flag = true;
        if(payrate==""){
            flag = false;
            $("#increase-wrap_"+dist_table).find('#increase_override_'+dist_table).addClass('red-input-alert');
        }else{
            $("#increase-wrap_"+dist_table).find('#increase_override_'+dist_table).removeClass('red-input-alert');
        }

        if(month==0){
            flag = false;
            $("#increase-wrap_"+dist_table).find('#increase_month_overrides_'+dist_table).addClass('red-input-alert');
        }else{
            $("#increase-wrap_"+dist_table).find('#increase_month_overrides_'+dist_table).removeClass('red-input-alert');
        }

        if(year=="" || year < 2000 || year > 2500){
            flag = false;
            $("#increase-wrap_"+dist_table).find('#increase_year_override_'+dist_table).addClass('red-input-alert');
        }else{
            $("#increase-wrap_"+dist_table).find('#increase_year_override_'+dist_table).removeClass('red-input-alert');
        }

        if(flag == false){ return false;}
        
        $('#increase_override_'+dist_table).val("");
        $('#increase_month_overrides_'+dist_table).val('0');
        $('#increase_year_override_'+dist_table).val("");

        if(payrate=="" || month==0 || year==""){
            return 0;
        }


        var flag = true;
        $.ajax({
            url: url,
            method:'POST',
            data:{
                user_id :user_id,
                dist_table :dist_table,
                payrate :payrate,
                month :month,
                year :year,
                edit_override :edit_override,
                _token: $("#csrf-token").val()
            }
        }).done(function(data){
            $('#cancel_override_'+dist_table).hide();
            $('#save_select_override_'+dist_table).show();

            if(dist_table==1){
                $('#save_override_datarx').data( "editoverride", 0 );
            }
            if(dist_table==1){
                $('#save_override_rxcut').data( "editoverride", 0 );
            }

            if(data.success==true){
                $('#override_increase_'+dist_table).html(data.html);
                $('.message_over_'+dist_table).html('<bold>Data successfully stored!</bold>');
                $('.message_over_'+dist_table).addClass('alert alert-success');
            }

            if(data.success==false){
                $('.message_over_'+dist_table).html('<bold>Increase or Year was not set propperly!</bold>');
                $('.message_over_'+dist_table).addClass('alert alert-danger');
                console.log("Increase or Year was not set propperly!");
            }
        });
    });




    /*Add overriders to distributor session*/
    $('#selectOverridersIframe').load(function() {
        $("#selectOverridersIframe").contents().find('input:checkbox.overriders').click(function() {
            var user_id = $('#user_id').val();
            var dist_table = main_dist_table;
            var url = $('#users').data('modaloverriderelectionurl');
            var thisCheckbox = $(this).val();
            $('#loading').show();
            $.ajax({
                url: url,
                method:'GET',
                data:{
                    user_id :user_id,
                    dist_table :dist_table,
                    thisCheckbox :thisCheckbox,
                    _token: $("#csrf-token").val()
                }
            }).done(function(data){
               $('#loading').hide();
            });
        });
    });

    $('#selectOverridersIframe').load(function() {
        $("#selectOverridersIframe").contents().find('.all_checkboxes').click(function() {





            // $('#loading').show();
            // $.ajax({
            //     url: url,
            //     method:'GET',
            //     data:{
            //         user_id :user_id,
            //         dist_table :dist_table,
            //         history_id :history_id,
            //         thisCheckbox :thisCheckbox,
                    
            //         _token: $("#csrf-token").val()
            //     }
            // }).done(function(data){
            //     // var src = $('iframe').attr('src');
            //     // var n = src.indexOf("reset");
            //     // if(n!=-1){
            //     //     src = src.substring(0, src.length - 1);
            //     // }
            //     // $("#selectOverridersIframe").attr('src', src);
            //     $('#loading').hide();
            // });

        });
    });

    $('#selectOverridersIframe').load(function() {
        $("#selectOverridersIframe").contents().find('input:checkbox.overriders_edit').click(function() {
            var user_id = $('#user_id').val();
            var dist_table = main_dist_table;
            var url = $('#users').data('editmodaloverriderelectionurl');
            var thisCheckbox = $(this).val();
            $('#loading').show();
            $.ajax({
                url: url,
                method:'GET',
                data:{
                    user_id :user_id,
                    dist_table :dist_table,
                    thisCheckbox :thisCheckbox,
                    _token: $("#csrf-token").val()
                }
            }).done(function(data){
               $('#loading').hide();
            });
        });
    });

    /*Add add distributor's overriders to Session*/
    $('.all').click(function(){
        var group_id = $('#users').data('usergroupid');
        var dist_table = $('#users').data('disttable');
        var dist_id = $('#users').data('distid');
        var url = $('#users').data('modaverriderelectionurl');

        $.ajax({
            url: url,
            method:'GET',
            data:{
                group_id :group_id,
                dist_id :dist_id,
                dist_table :dist_table,
                thisCheckbox :'all',
                _token: $("#csrf-token").val()
            }
        }).done(function(data){
            $('.message_over_'+dist_table).html('<bold>All distributors have been selected</bold>');
            $('.message_over_'+dist_table).addClass('alert alert-success');
        });
    });

    $('.select-check').click(function(){
        var url = $('#users').data('saveeditselected');
        var user_id = $('#user_id').val();
        var baseUrl = $('#users').data('baseurl');
        $.ajax({
            url: url,
            method:'GET',
            data:{
                main_history_id :main_history_id,
                dist_table :main_dist_table,
                user_id :user_id,
                _token: $("#csrf-token").val()
            }
        }).done(function(data){
            $('body').find('.message_over_'+main_dist_table).html('<bold>Checked distributors have been selected</bold>');
            $('body').find('.message_over_'+main_dist_table).addClass('alert alert-success');
            $('body').find('.span_'+main_history_id+'-'+main_dist_table).html(data.selection);
            //window.location = baseUrl+'/addUsers?id='+user_id;
        });
    });

    /*Edit PayRate*/
    function getMonth(month){
        var months =  {"January":"01","February":"02","March":"03","April":"04","May":"05","June":"06","July":"07","August":"08","September":"09","October":"10","November":"11","December":"12"};

        return months[month];
    }

    $('.payrate_edit_1,payrate_edit_2,.override_edit_1,override_edit_2').click(function(e){
        $('#increase_payrate,#increase_month_payrates,#increase_year_payrate,#increase_override,#increase_month_overrides,#increase_year_override').removeClass('red-input-alert');
    });

    $('#cancel_payrate_1').click(function(e){
        $('#increase_payrate_1').val("");
        $('#increase_month_payrates_1').val(0);
        $('#increase_year_payrate_1').val("");

        $('#cancel_payrate_1').hide();
        $('#save_pay_rate_datarx').data( "editpayrate", 0 );
    });

    $('#cancel_payrate_2').click(function(e){
        $('#increase_payrate_2').val("");
        $('#increase_month_payrates_2').val(0);
        $('#increase_year_payrate_2').val("");

        $('#cancel_payrate_2').hide();
        $('#save_pay_rate_rxcut').data( "editpayrate", 0 );
    });

    $('#cancel_override_1,#cancel_override_2').click(function(e){
        var dist_table = $(this).data('dist_table');
        $('#increase_override_'+dist_table).val("");
        $('#increase_month_overrides_'+dist_table).val(0);
        $('#increase_year_override_'+dist_table).val("");

        $('#cancel_override_'+dist_table).hide();
        $('#save_select_override_'+dist_table).show();
        $('#save_override_'+dist_table).data( "editoverride", 0 );
    });

    $("#app-layout").on('click','.payrate_edit_1,.payrate_edit_2', function (e) {
        e.preventDefault();
        var dist_table = $(this).data('dist_table');
        $('#cancel_payrate_'+dist_table).show();
        $('#loading').show();
        var dist_id = $('#user_id').val();
        var payrate_id = $(this).data('payrate');

        if(dist_table){
            $('#save_pay_rate_'+dist_table).data( "editpayrate", payrate_id );
        }

        var url = $('#users').data('geteditpayrate');

        $.ajax({
            url: url,
            method:'GET',
            data:{
                dist_id :dist_id,
                dist_table :dist_table,
                payrate_id :payrate_id,
                _token: $("#csrf-token").val()
            }
        }).done(function(data){
            $('#increase_payrate_'+dist_table).val(data.data[0].increase);
            var month = getMonth(data.data[0].month);
            $('#increase_month_payrates_'+dist_table).val(month);
            $('#increase_year_payrate_'+dist_table).val(data.data[0].year);
            $('#loading').hide();
        });
    });


$(".groupid_chk").keyup(function(){
    var idChk = $(this).val();
    var id = $(this).attr('prog');
    var url = $(this).attr('url');
    var prefix = $(this).attr('prefix');
    var prefixArray = prefix.split(',');
 
    var text = idChk.replace(/\d/g, '');
    var yes = jQuery.inArray( text, prefixArray );

    if(yes >= 0){
        $.ajax({
            type: "POST",
            data: {
                idChk: idChk,
                _token: $('[name="_token"]').val()
            },
            url: url,
            success: function(data)
            {   
                if(data==1){
                    $('#group_check_'+id).html(idChk+" - This Group ID already in use.");
                    return;
                } else if(data==0){
                    $('#group_check_'+id).html(' ');
                    return;
                }
            }
        });
    } else{
        $('#group_check_'+id).html(idChk+" - This Group ID is not a valid format.");
    }
});


$(".groupid_chk").blur(function(){
    var idChk = $(this).val();
    var id = $(this).attr('prog');
    var lengths = idChk.length;
    if( lengths == 0 ){
    $('#group_check_'+id).html(' ');

    }

});



$(".overid_chk").blur(function(){
    var idChk = $(this).val();
    var id = $(this).attr('prog');
    
    if((idChk.length) <=0 ){
    $('#over_check_'+id).html(' ');

    }

});



$(".overid_chk").keyup(function(){
    var idChk = $(this).val();
    var id = $(this).attr('prog');
    var url = $(this).attr('url');
    var prefix = $(this).attr('prefix');
    var prefixArray = prefix.split(',');
    
    var text = idChk.replace(/\d/g, '');
    var yes = jQuery.inArray( text, prefixArray );

    if(yes >= 0){
        $.ajax({
            type: "POST",
            data: {
                idChk: idChk,
                _token: $('[name="_token"]').val()
            },
            url: url,
            success: function(data)
            {   
                if(data==1){
                  $('#over_check_'+id).html(' ');
                    return;
                } else if(data==0){
                    $('#over_check_'+id).html(idChk+" - This Group ID is Invalid.");
                    return;
                }
            }
        });
    } else{
        $('#over_check_'+id).html(idChk+" - This Group ID is not a valid format.");
    }
});


    $(".increase-wrap").on('click','.override_edit', function (e) {
        e.preventDefault();

        var group_id = $('#users').data('usergroupid');
        var dist_table = $(this).data('dist_table');
        var user_id = $('#user_id').val();
        var override = $(this).data('override');

        $('#save_select_override_'+dist_table).hide();
        $('#cancel_override_'+dist_table).show();
        $('#loading').show();

        if(dist_table){
            $('.editoverride'+dist_table).data( "editoverride", override );
        }

        var url = $('#users').data('geteditoverride');

        $.ajax({

            url: url,
            method:'GET',
            data:{
                group_id :group_id,
                user_id :user_id,
                dist_table :dist_table,
                override :override,
                _token: $("#csrf-token").val()
            }
        }).done(function(data){
            $('#increase_override_'+dist_table).val(data.data[0].increase);
            var month = getMonth(data.data[0].month);
            $('#increase_month_overrides_'+dist_table).val(month);
            $('#increase_year_override_'+dist_table).val(data.data[0].year);
            $('#loading').hide();
        });
    });

/*Distributors payrates/overrides increase END*/
    /*Data Export*/
    /*$(".generateDataExcel").on('click', function (e) {
        e.preventDefault();
        $('#loading').show();
        var url = $('#generateExcelRoute').val();
        $.ajax({
            type: "POST",
            data: {
                card_program: $('#card_program').val(),
                date_from: $('#date_from').val(),
                date_to: $('#date_to').val(),
                _token: $('[name="_token"]').val()
            },
            url: url,
            success: function(data)
            {   
                if(data.success==false){
                    $('#loading').hide();
                    $('#server-message').html(data.html);
                    return;
                }
            }
        });
        $('#loading').hide();
    });*/

    
    /*End Data Export*/
    /*Generate Excel Submit*/
    $(".generateExcelButton1").on('click', function (e) {
        /*$('#generateExcelForm').submit(function(){*/
            $(".generateExcelButton").prop('disabled', true);
            $.ajax({
                url: $('#generateExcelRoute').val(),
                type: 'post',
                data : $('#generateExcelForm').serialize(),
                success: function(data){
                    if(data.success==false){
                        //fa un partial de mesaje si pune l ca html direct in div
                    }
                    $(".generateExcelButton").prop('disabled', false);
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    alert(XMLHttpRequest, textStatus, errorThrown);
                }
            });
            return false;
        /*});*/
    });
    /*END Generate Excel Submit*/

    $('.select').attr('disabled','disabled');

    $( "#representants" ).change(function() {
        if($('#representants').val()=='0'){
            $('.select').attr('disabled','disabled');
        }else{
            $('.select').removeAttr('disabled');
        }
    });

    /*Drop down for selecting distributors with tranzactions or dist that don't have transactions but are listed as overrides*/
     $( "#dist_data" ).change(function() {
        var dist_data_val = $('#dist_data').val();
        var dataidurl = $(this).data('dataidurl');
        
        if($('#dist_data').val()=='2'){
           window.location=dataidurl+'?dist_data=2';
        }else{
            window.location=dataidurl+'?dist_data=1';
        }
    });

    /******************************/

    /*Drop down for selecting distributors with tranzactions or dist that don't have transactions but are listed as overrides for both RxData and Rx-Cut in frontend*/
     $( "#dist_data_front" ).change(function() {
        var dist_data_val = $('#dist_data_front').val();
        
        window.location=dist_data_val;
    });

    /******************************/

    $(".listcheck").change(function() {
        if ($("#lists-form input:checkbox:checked").length > 0)
        {
            $('.listbtncheck').removeAttr('disabled');
        }
        else
        {
           $('.listbtncheck').attr('disabled','disabled');
        }
    });

    /*Upload File Full Name Display*/

    var check_data = document.getElementById('excel_file');

    if(check_data){
        check_data.onchange = uploadOnChange;
    }
       
       
    function uploadOnChange() {
        var filename = this.value;
        var lastIndex = filename.lastIndexOf("\\");
        if (lastIndex >= 0) {
            filename = filename.substring(lastIndex + 1);
        }
        document.getElementById('upload_file').innerHTML = filename;
    }


    $(document).on('click', '.browse_file', function(){
        var file = $(this).parent().parent().parent().find('.distributor_files');
        file.trigger('click');
    });


    /**************************/

    /*if(!document.getElementById('with-overall').checked && !document.getElementById('with-distributordata').checked){
        $( "#with-overall" ).prop( "checked", true );
        $( "#with-distributordata" ).prop( "checked", true );
    }
    $('#with-overall').click(function(){
        var url = $('#with-overall').val();
        window.location=url;
    });
    $('#with-distributordata').click(function(){
        var url = $('#with-distributordata').val();
        window.location=url;
    });*/
    //Distributor Dashboard End////
})(window, document, jQuery);

